import React from 'react';
import { Box, Container, Typography, Paper, Grid, Link } from '@mui/material';
import { red } from '@mui/material/colors';

const mockIncident = {
    title: 'Database Connectivity Incident',
    overview: 'There is currently an issue affecting database connectivity. Our engineering team is investigating the cause and working on a resolution.',
    status: 'Major Outage',
    affectedSystems: 'Database Services',
    started: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()} (UTC)`,
    estimatedResolution: '2-3 hours',
    supportContact: 'support@brightfuture.co',
};

const StatusPage = () => {
    return (
        <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {mockIncident.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Dear valued customers,
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We apologize for the inconvenience caused by the current issue affecting our database connectivity. Our engineering team is working diligently to investigate the cause and resolve it as soon as possible. Thank you for your patience and understanding.
                </Typography>
                <Box sx={{ my: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', color: red[500] }}>
                        <span style={{ marginRight: '8px' }}>●</span> {mockIncident.status}
                    </Typography>
                    <Grid container spacing={2} sx={{ my: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="div">
                                <strong>Affected Systems:</strong> {mockIncident.affectedSystems}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="div">
                                <strong>Started:</strong> {mockIncident.started}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="div">
                                <strong>Estimated Resolution Time:</strong> {mockIncident.estimatedResolution}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" gutterBottom>
                        For immediate assistance, please contact our support team at <Link href={`mailto:${mockIncident.supportContact}`}>{mockIncident.supportContact}</Link>.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default StatusPage;
