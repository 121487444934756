import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, Container, Alert, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const RequestEarlyAccess = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [urgency, setUrgency] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/v1/default/early-access', {
                email,
                name,
                companySize,
                urgency,
            });
            if (response.status === 201) {
                setMessage('Request submitted successfully.');
                setMessageType('success');
            } else {
                setMessage(response.data.error || 'Failed to submit request');
                setMessageType('error');
            }
        } catch (error) {
            setMessage(`An error occurred: ${error.response?.data?.error}. Please try again.`);
            setMessageType('error');
        }
        setTimeout(() => setMessage(''), 5000);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Join our waitlist
                </Typography>
                <Typography variant="body1">
                    To request early access to Ewake.ai, please fill out the form below
                </Typography>
                {message && (
                    <Alert severity={messageType} sx={{ mt: 2, mb: 2 }}>
                        {message}
                    </Alert>
                )}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Work Email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FormControl component="fieldset" sx={{ mt: 2, minWidth: '50%' }}>
                        <Typography variant="body1">Company Size</Typography>
                        <RadioGroup
                            aria-label="companySize"
                            name="companySize"
                            value={companySize}
                            onChange={(e) => setCompanySize(e.target.value)}
                        >
                            <FormControlLabel value="1-10" control={<Radio />} label="1-10" />
                            <FormControlLabel value="10-100" control={<Radio />} label="10-100" />
                            <FormControlLabel value="100-500" control={<Radio />} label="100-500" />
                            <FormControlLabel value="500+" control={<Radio />} label="500+" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" sx={{ mt: 2, minWidth: '50%' }}>
                        <Typography variant="body1">Urgency</Typography>
                        <RadioGroup
                            aria-label="urgency"
                            name="urgency"
                            value={urgency}
                            required
                            onChange={(e) => setUrgency(e.target.value)}
                        >
                            <FormControlLabel value="immediately" control={<Radio />} label="Immediately" />
                            <FormControlLabel value="in3-6months" control={<Radio />} label="In 3-6 months" />
                            <FormControlLabel value="noturgent" control={<Radio />} label="Not urgent" />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default RequestEarlyAccess;
