import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "./loading.json"; // Adjust the path as needed
import "./Insights.css"; // Import the CSS file
import DashboardNav from "./DashboardNav";

const mockInsights = [
    "The IAM team is spending 35% of their time on incident resolution.",
    "The Video online bidding team is spending 40% of their time on incident resolution.",
    "Alice Johnson faced 5 incidents in the last month.",
    "Bob Smith resolved 3 critical incidents in the past week.",
    "The Search component is shaky and had faced 10 incidents in the last month.",
];

const Insights = () => {
    const [loading, setLoading] = useState(true);
    const [displayedInsights, setDisplayedInsights] = useState([]);

    useEffect(() => {
        const loadingTimeout = setTimeout(() => setLoading(false), 3000);
        return () => clearTimeout(loadingTimeout);
    }, []);

    useEffect(() => {
        if (!loading && displayedInsights.length < mockInsights.length) {
            const insightTimeout = setTimeout(() => {
                setDisplayedInsights((prev) => [...prev, mockInsights[prev.length]]);
            }, 1000); // Adjust delay between insights here
            return () => clearTimeout(insightTimeout);
        }
    }, [loading, displayedInsights]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <DashboardNav />
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}>
                    <Typography variant="h4" gutterBottom>
                        Loading insights...
                    </Typography>
                    <Lottie options={defaultOptions} height={150} width={150} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, p: 3, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4" gutterBottom>
                        Company Insights
                    </Typography>
                    <Grid container spacing={3} sx={{ mt: 3 }}>
                        {displayedInsights.map((insight, index) => (
                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <Paper
                                    className="fade-in"
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                    }}>
                                    <Typography variant="body1">{insight}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default Insights;
