import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setIsAuthenticated(true);
        setUser({
            username: 'Demo user',
            company: {
                yearlyIncidents: 132,
                yearlyIncidentIncrease: 9,
                monthlyIncidents: 10,
                monthlyIncidentIncrease: 9,
                incidents: [
                    {
                        description: "Payment received from John Doe",
                        date: "2023-03-01T09:30:00Z",
                    },
                    {
                        description: "New sale recorded",
                        date: "2023-03-01T10:00:00Z",
                    },
                ],
            },
        });
    }, []);

    const login = async (email, password) => {
        try {
            setIsAuthenticated(true);
            setUser({
                username: 'Demo User',
                company: {
                    yearlyIncidents: 132,
                    yearlyIncidentIncrease: 9,
                    monthlyIncidents: 10,
                    monthlyIncidentIncrease: 9,
                    incidents: [
                        {
                            description: "Payment received from John Doe",
                            date: "2023-03-01T09:30:00Z",
                        },
                        {
                            description: "New sale recorded",
                            date: "2023-03-01T10:00:00Z",
                        },
                    ],
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const logout = () => {
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
