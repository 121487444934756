import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Groups3Icon from '@mui/icons-material/Groups3';
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import { Link } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import BarChartIcon from '@mui/icons-material/BarChart';

const DashboardNav = () => {
    return (
        <Box sx={{ width: 240, backgroundColor: "#f4f4f4", p: 2 }}>
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/knowledgebase">
                    <ListItemIcon>
                        <TipsAndUpdatesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Knowledge Base" />
                </ListItem>
                <ListItem button component={Link} to="/incident-insights">
                    <ListItemIcon>
                        <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Incident Insights" />
                </ListItem>
                <ListItem button component={Link} to="/incident-metrics">
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Incident Metrics" />
                </ListItem>
                <ListItem button component={Link} to="/team-orga">
                    <ListItemIcon>
                        <Groups3Icon />
                    </ListItemIcon>
                    <ListItemText primary="Team Organization" />
                </ListItem>
                <ListItem button component={Link} to="/integrations">
                    <ListItemIcon>
                        <SettingsInputCompositeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Integrations" />
                </ListItem>
                <ListItem button component={Link} to="/usage">
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bills" />
                </ListItem>
                <ListItem button component={Link} to="/payments">
                    <ListItemIcon>
                        <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Payments" />
                </ListItem>
            </List>
        </Box>
    );
};

export default DashboardNav;
