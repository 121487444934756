// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0e4f8f", // Primary color
        },
        secondary: {
            main: "#dc004e", // Secondary color
        },
        background: {
            default: "#f2f2f2", // Default background color
        },
    },
    typography: {
        fontFamily: '"Fira Code", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    shadows: Array(25).fill("none"), // Remove all shadows
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 8, // Rounded corners
                        "& fieldset": {
                            borderColor: "#ddd", // Light border color
                        },
                        "&:hover fieldset": {
                            borderColor: "#ccc", // Darker border on hover
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#1976d2", // Primary color when focused
                        },
                    },
                    "& .MuiInputLabel-root": {
                        fontSize: "1rem", // Modern label size
                    },
                    "& .MuiOutlinedInput-input": {
                        padding: "12px", // Padding for input
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit", // Primary color
                    color: "black", // White text color
                    borderRadius: 8, // Rounded corners
                    textTransform: "none", // Disable uppercase transformation
                    boxShadow: "none", // Remove button shadow
                    padding: "8px 16px", // Modern padding
                    "&:hover": {
                        boxShadow: "none", // Remove hover shadow
                        backgroundColor: "black", // Ensure hover color matches primary,
                        color:"white"
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit", // Inherit theme background color
                    border: "1px solid rgba(0, 0, 0, 0.12)", // Minimal transparent border
                    borderRadius: 8, // Rounded corners
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit", // Inherit theme background color
                    border: "1px solid rgba(0, 0, 0, 0.12)", // Minimal transparent border
                    borderRadius: 8, // Rounded corners
                    boxShadow: "none", // Remove shadow
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#f2f2f2", // Inherit theme background color
                    border: "1px solid rgba(0, 0, 0, 0.12)", // Minimal transparent border
                    borderRadius: 8, // Rounded corners
                    boxShadow: "none", // Remove shadow
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none", // Remove shadow
                    border: "none",
                    backgroundColor: "inherit", // Inherit theme background color
                    color: "inherit", // Inherit theme text color
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "inherit", // Inherit theme background color
                    border: "none",
                    boxShadow: "none", // Remove shadow
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f2f2f2", // Inherit theme background color
                },
            },
        },
    },
});

export default theme;
