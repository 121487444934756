import React from 'react';

const Logo = ({ width = 50, height = 50, src }) => {
    return (
        <img
            src={src} 
            alt="Logo"
            width={width}
            height={height}
            color='primary'
            style={{ display: 'block' }}
        />
    );
};

export default Logo;
