import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Layout from "./components/Layout";
import KnowledgeBase from "./components/KnowledgeBase";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import RequestEarlyAccess from "./components/RequestEarlyAccess";
import Integrations from "./components/Integrations";
import IncidentMetrics from "./components/IncidentMetrics";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import NotImplemented from "./components/NotImplemented";
import Insights from "./components/IncidentInsights";
import StatusPage from "./components/StatusPage";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <Router>
                    <Layout>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <Dashboard />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/dashboard"
                                element={
                                    <ProtectedRoute>
                                        <Dashboard />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/early-access" element={<RequestEarlyAccess />} />
                            <Route
                                path="/team-orga"
                                element={
                                    <ProtectedRoute>
                                        <NotImplemented />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/knowledgebase"
                                element={
                                    <ProtectedRoute>
                                        <KnowledgeBase />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/incident-metrics"
                                element={
                                    <ProtectedRoute>
                                        <IncidentMetrics />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/integrations"
                                element={
                                    <ProtectedRoute>
                                        <Integrations />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/usage"
                                element={
                                    <ProtectedRoute>
                                        <NotImplemented />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/payments"
                                element={
                                    <ProtectedRoute>
                                        <NotImplemented />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/incident-insights"
                                element={
                                    <ProtectedRoute>
                                        <Insights />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/status-page" element={<StatusPage />} />
                            <Route path="/register" element={<NotImplemented />} />
                        </Routes>
                    </Layout>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
