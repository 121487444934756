import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Container,
    Typography,
    Dialog,
    Tabs,
    Tab,
    Box,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/system";
import DashboardNav from "./DashboardNav";

const mockNodes = Array.from({ length: 100 }, (_, i) => ({
    id: i,
    group: Math.floor(Math.random() * 10),
}));
const mockLinks = Array.from({ length: 200 }, () => ({
    source: Math.floor(Math.random() * 100),
    target: Math.floor(Math.random() * 100),
}));

const randomNameGenerator = () => {
    // a bunch of first names and last names and then pick one of each at random
    const firstNames = [
        "John",
        "Jane",
        "Alice",
        "Bob",
        "Charlie",
        "David",
        "Eve",
        "Frank",
        "Grace",
        "Hank",
        "Ivy",
    ];
    const lastNames = [
        "Smith",
        "Doe",
        "Johnson",
        "Brown",
        "Davis",
        "Miller",
        "Wilson",
        "Moore",
        "Taylor",
        "Anderson",
        "Thomas",
    ];
    return `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${
        lastNames[Math.floor(Math.random() * lastNames.length)]
    }`;
};

const mockKnowledgeBaseData = {
    confluence: {
        activated: true,
        documents: mockNodes.slice(0, 50).map((node, i) => ({
            id: node.id,
            title: `Document ${i + 1}`,
            content: `Details about Document ${i + 1}`,
            relatedPeople: mockNodes.slice(i + 1, i + 4).map((n) => randomNameGenerator()),
        })),
    },
    slack: {
        activated: true,
        messages: mockNodes.slice(50).map((node, i) => ({
            id: node.id,
            text: `Slack message ${i + 1}`,
            relatedPeople: mockNodes.slice(i + 1, i + 4).map((n) => randomNameGenerator()),
        })),
    },
};

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const StyledLink = styled("a")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
}));

const KBModel = ({ open, handleClose, modalContent }) => (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <StyledDialogTitle>
            <Typography variant="h6">Profile</Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
            </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
            <Typography variant="body1" gutterBottom>
                <strong>Title:</strong>{" "}
                <StyledLink href={modalContent.link} target="_blank" rel="noopener noreferrer">
                    {modalContent.title}
                </StyledLink>
            </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
                Close
            </Button>
        </StyledDialogActions>
    </Dialog>
);

const KnowledgeBaseGraph = () => {
    const [term, setTerm] = useState("");
    const [results, setResults] = useState([]);
    const [highlightedNodes, setHighlightedNodes] = useState([]);
    const [activeSources, setActiveSources] = useState({ confluence: true, slack: true });
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", link: "", source: "" });

    useEffect(() => {
        fetchData();
    }, [activeSources]);

    const fetchData = () => {
        const termLower = term.toLowerCase();
        const results = [];

        if (activeSources.confluence) {
            results.push(
                ...mockKnowledgeBaseData.confluence.documents.filter(
                    (doc) =>
                        doc.title.toLowerCase().includes(termLower) ||
                        doc.content.toLowerCase().includes(termLower)
                )
            );
        }
        if (activeSources.slack) {
            results.push(
                ...mockKnowledgeBaseData.slack.messages.filter((msg) =>
                    msg.text.toLowerCase().includes(termLower)
                )
            );
        }

        setResults(results);
    };

    const toggleSource = (source) => {
        const newActiveSources = { ...activeSources, [source]: !activeSources[source] };
        setActiveSources(newActiveSources);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const randomNodes = mockNodes
            .sort(() => 0.5 - Math.random())
            .slice(0, 10)
            .map((node) => node.id);
        setHighlightedNodes(randomNodes);
    };

    useEffect(() => {
        drawGraph();
    }, [highlightedNodes]);

    const drawGraph = () => {
        d3.select("#graph").selectAll("*").remove();
        const svg = d3.select("#graph").append("svg").attr("width", "640").attr("height", "480");

        const simulation = d3
            .forceSimulation()
            .force(
                "link",
                d3.forceLink().id((d) => d.id)
            )
            .force("charge", d3.forceManyBody().strength(-100))
            .force("center", d3.forceCenter(320, 240));

        const link = svg
            .append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(mockLinks)
            .enter()
            .append("line")
            .attr("stroke-width", 1.5)
            .attr("stroke", "#999")
            .attr("opacity", 0.5);

        const node = svg
            .append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(mockNodes)
            .enter()
            .append("circle")
            .attr("r", 5)
            .attr("fill", (d) => (highlightedNodes.includes(d.id) ? "red" : "blue"))
            .attr("opacity", (d) => (highlightedNodes.includes(d.id) ? 1 : 0.5))
            .on("click", (d) => {
                const type = "Person";
                setModalContent({
                    title: `${randomNameGenerator()}`,
                    link: `https://confluence.example.com/${type.toLowerCase()}/${d.id}`,
                    source: 1,
                });
                setOpen(true);
            })
            .call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));

        node.append("title").text((d) =>
            highlightedNodes.includes(d.id) ? `Fake Link: Person ${d.id}` : `Node ${d.id}`
        );

        svg.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(mockNodes.filter((d) => highlightedNodes.includes(d.id)))
            .enter()
            .append("text")
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            .attr("dy", -10)
            .attr("text-anchor", "middle")
            .text((d) => `${randomNameGenerator()}`)
            .attr("fill", "black");

        simulation.nodes(mockNodes).on("tick", ticked);
        simulation.force("link").links(mockLinks);

        function ticked() {
            link.attr("x1", (d) => d.source.x)
                .attr("y1", (d) => d.source.y)
                .attr("x2", (d) => d.target.x)
                .attr("y2", (d) => d.target.y);

            node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

            svg.selectAll("text")
                .attr("x", (d) => d.x)
                .attr("y", (d) => d.y);
        }

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <DashboardNav />
            <Box sx={{ flexGrow: 1, p: 3, display: "flex", flexDirection: "row" }}>
                <Container sx={{ flexGrow: 1, width: "100%" }}>
                    <Typography variant="h4" gutterBottom>
                        Knowledge Base Graph
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box mt={2} mb={2}>
                                <form
                                    onSubmit={handleSearch}
                                    style={{ marginBottom: "20px", width: "100%" }}>
                                    <TextField
                                        label="Search term"
                                        variant="outlined"
                                        value={term}
                                        onChange={(e) => setTerm(e.target.value)}
                                        style={{ marginRight: "10px", width: "calc(100% - 120px)" }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{ height: "56px" }}>
                                        Search
                                    </Button>
                                </form>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={activeSources.confluence}
                                                onChange={() => toggleSource("confluence")}
                                            />
                                        }
                                        label="Confluence"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={activeSources.slack}
                                                onChange={() => toggleSource("slack")}
                                            />
                                        }
                                        label="Slack"
                                    />
                                </FormGroup>
                            </Box>
                            <div id="graph"></div>
                            <KBModel
                                open={open}
                                handleClose={handleClose}
                                modalContent={modalContent}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default KnowledgeBaseGraph;
