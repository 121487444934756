import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import Logo from "./Logo";

const Layout = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <Logo width={40} height={40} src={"/logo-hd.svg"} />

                    <Typography variant="h6" sx={{ flexGrow: 1, marginLeft: "10px" }}>
                        <Link
                            to="https://ewake.ai"
                            style={{ textDecoration: "none", color: "inherit" }}>
                            {" "}
                            Ewake.ai{" "}
                        </Link>
                    </Typography>
                    <Button color="inherit" component={Link} to="/login">
                        Home
                    </Button>
                    {isAuthenticated ? (
                        <>
                            <Button color="inherit" component={Link} to="/dashboard">
                                Dashboard
                            </Button>
                            {/* <Button color="inherit" onClick={logout}>
                                Logout
                            </Button> */}
                        </>
                    ) : (
                        <>
                            <Button color="inherit" component={Link} to="/login">
                                Login
                            </Button>
                            <Button color="inherit" component={Link} to="/register">
                                Register
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ p: 3 }}>
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
