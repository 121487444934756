import React from "react";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import WebhookIcon from "@mui/icons-material/Http";
import DashboardNav from "./DashboardNav";
import Logo from "./Logo";

const integrations = [
    {
        name: "Sentry",
        description: "Error tracking and performance monitoring.",
        icon: <Logo src={'sentry.svg'} />,
    },
    { name: "Datadog", description: "Monitoring and security platform.", icon: <Logo src={"/logo-08.svg"} /> },
    { name: "Opsgenie", description: "Incident response platform.", icon: <Logo src={"/opsgenie.svg"} /> },
    { name: "Confluence", description: "Collaborative knowledge base.", icon: <Logo src={"/confluence.svg"} /> },
    { name: "Slack", description: "Team communication platform.", icon: <Logo src={"/slack.svg"} /> },
    { name: "GitHub", description: "Code repository management.", icon: <Logo src={"/github.svg"} /> },
    { name: "Webhook", description: "Send HTTP calls to our endpoint.", icon: <Logo src={"/http.png"} /> },
];

const Integrations = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
                <DashboardNav />
                <Grid item xs={12} md={9}>
                    <Typography variant="h4" gutterBottom>
                        Integrations
                    </Typography>
                    <Grid container spacing={3}>
                        {integrations.map((integration, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Paper sx={{ p: 3, display: "flex", alignItems: "center" }}>
                                    <Box sx={{ mr: 2 }}>{integration.icon}</Box>
                                    <Box>
                                        <Typography variant="h6">{integration.name}</Typography>
                                        <Typography variant="body1">
                                            {integration.description}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h5" gutterBottom>
                        Webhook Integration
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Use the following URL to send HTTP calls to our endpoint:
                    </Typography>
                    <Paper sx={{ p: 2, backgroundColor: "#f5f5f5" }}>
                        <Typography variant="body2">
                            https://your-domain.com/webhook-endpoint
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Integrations;
