import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "./loading.json"; // Adjust the path as needed

import {
    AppBar,
    Toolbar,
    Typography,
    Select,
    MenuItem,
    FormControl,
    Grid,
    Paper,
    Box,
    Divider,
} from "@mui/material";
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    PieChart,
    Pie,
    Cell,
    Tooltip,
    Legend,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import DashboardNav from "./DashboardNav";

// Mock data
const mockData = {
    organization: {
        totalIncidents: 120,
        incidentSeverity: { critical: 20, high: 30, medium: 40, low: 30 },
        incidentTrends: [
            { date: "2024-01", incidents: 20 },
            { date: "2024-02", incidents: 30 },
            { date: "2024-03", incidents: 25 },
            { date: "2024-04", incidents: 45 },
            { date: "2024-05", incidents: 55 },
            { date: "2024-06", incidents: 15 },
        ],
        MTTA: 15,
        MTTR: 60,
        teamTimeExhausted: [
            { name: "Alice", timePercentage: 35 },
            { name: "Bob", timePercentage: 8 },
            { name: "Charlie", timePercentage: 20 },
        ],
        memberInvolvement: [
            { name: "Alice", critical: 5, high: 10, medium: 3, low: 2 },
            { name: "Bob", critical: 7, high: 8, medium: 5, low: 5 },
            { name: "Charlie", critical: 8, high: 7, medium: 10, low: 5 },
        ],
    },
    teams: {
        preplatform: {
            totalIncidents: 50,
            incidentSeverity: { critical: 10, high: 15, medium: 15, low: 10 },
            incidentTrends: [
                { date: "2024-01", incidents: 5 },
                { date: "2024-02", incidents: 15 },
                { date: "2024-03", incidents: 10 },
                { date: "2024-04", incidents: 20 },
                { date: "2024-05", incidents: 35 },
                { date: "2024-06", incidents: 15 },
            ],
            MTTA: 10,
            MTTR: 45,
            teamTimeExhausted: [
                { name: "David", timePercentage: 25 },
                { name: "Eve", timePercentage: 10 },
                { name: "Frank", timePercentage: 15 },
            ],
            memberInvolvement: [
                { name: "David", critical: 5, high: 5, medium: 5, low: 5 },
                { name: "Eve", critical: 5, high: 5, medium: 5, low: 0 },
                { name: "Frank", critical: 0, high: 5, medium: 5, low: 5 },
            ],
        },
        videoteam: {
            totalIncidents: 30,
            incidentSeverity: { critical: 5, high: 10, medium: 10, low: 5 },
            incidentTrends: [
                { date: "2024-01", incidents: 10 },
                { date: "2024-02", incidents: 5 },
                { date: "2024-03", incidents: 10 },
                { date: "2024-04", incidents: 5 },
                { date: "2024-05", incidents: 25 },
                { date: "2024-06", incidents: 15 },
            ],
            MTTA: 20,
            MTTR: 90,
            teamTimeExhausted: [
                { name: "Grace", timePercentage: 30 },
                { name: "Harry", timePercentage: 20 },
                { name: "Ivy", timePercentage: 15 },
            ],
            memberInvolvement: [
                { name: "Grace", critical: 2, high: 3, medium: 3, low: 2 },
                { name: "Harry", critical: 2, high: 3, medium: 2, low: 3 },
                { name: "Ivy", critical: 1, high: 4, medium: 5, low: 0 },
            ],
        },
        iamteam: {
            totalIncidents: 40,
            incidentSeverity: { critical: 5, high: 5, medium: 15, low: 15 },
            incidentTrends: [
                { date: "2024-01", incidents: 5 },
                { date: "2024-02", incidents: 10 },
                { date: "2024-03", incidents: 5 },
                { date: "2024-04", incidents: 20 },
                { date: "2024-05", incidents: 5 },
                { date: "2024-06", incidents: 15 },
            ],
            MTTA: 30,
            MTTR: 120,
            teamTimeExhausted: [
                { name: "Jack", timePercentage: 40 },
                { name: "Kate", timePercentage: 25 },
                { name: "Liam", timePercentage: 20 },
                { name: "Mia", timePercentage: 10 },
                { name: "Noah", timePercentage: 5 },
            ],
            memberInvolvement: [
                { name: "Jack", critical: 3, high: 3, medium: 5, low: 4 },
                { name: "Kate", critical: 2, high: 2, medium: 5, low: 6 },
                { name: "Liam", critical: 0, high: 0, medium: 5, low: 5 },
                { name: "Mia", critical: 0, high: 0, medium: 5, low: 5 },
                { name: "Noah", critical: 2, high: 0, medium: 5, low: 3 },
            ],
        },
    },
};

const IncidentMetrics = () => {
    const [loading, setLoading] = useState(true);
    const [selectedTeam, setSelectedTeam] = useState("organization");
    const [data, setData] = useState(mockData.organization);

    useEffect(() => {
        const loadingTimeout = setTimeout(() => setLoading(false), 3000);
        return () => clearTimeout(loadingTimeout);
    }, []);

    useEffect(() => {
        if (selectedTeam === "organization") {
            setData(mockData.organization);
        } else {
            setData(mockData.teams[selectedTeam]);
        }
    }, [selectedTeam]);

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const severityData = Object.keys(data.incidentSeverity).map((key) => ({
        name: key.charAt(0).toUpperCase() + key.slice(1),
        value: data.incidentSeverity[key],
    }));

    const COLORS = ["#b50202", "#d99900", "#FFCD56", "#02a602"];

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <DashboardNav />
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}>
                    <Typography variant="h4" gutterBottom>
                        Loading insights...
                    </Typography>
                    <Lottie options={defaultOptions} height={150} width={150} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Incident Management Metrics
                            </Typography>
                            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                                <Select value={selectedTeam} onChange={handleTeamChange}>
                                    <MenuItem value="organization">Organization</MenuItem>
                                    <MenuItem value="preplatform">PRE-Platform</MenuItem>
                                    <MenuItem value="videoteam">Video Team</MenuItem>
                                    <MenuItem value="iamteam">IAM Team</MenuItem>
                                </Select>
                            </FormControl>
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} sx={{ p: 3 }}>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6">
                                    Mean Time to Acknowledge (MTTA)
                                </Typography>
                                <Typography variant="h3" sx={{ mt: 1 }}>
                                    {data.MTTA} minutes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6">Mean Time to Resolve (MTTR)</Typography>
                                <Typography variant="h3" sx={{ mt: 1 }}>
                                    {data.MTTR} minutes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6">Total Incidents</Typography>
                                <Typography variant="h3" sx={{ mt: 1 }}>
                                    {data.totalIncidents}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Incident Trends
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={data.incidentTrends}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey="incidents"
                                            stroke="#8884d8"
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Incident Severity Distribution
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={severityData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ name }) => name}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value">
                                            {severityData.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Team's Time Exhausted Over Incidents
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={data.teamTimeExhausted}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="timePercentage" fill="#8884d8">
                                            {data.teamTimeExhausted.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={
                                                        entry.timePercentage > 30
                                                            ? "#b50202"
                                                            : entry.timePercentage < 10
                                                            ? "#02a602"
                                                            : "#d99900"
                                                    }
                                                />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3, textAlign: "center" }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Member Involvement in Incident Severity
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={data.memberInvolvement}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="critical" stackId="a" fill="#b50202" />
                                        <Bar dataKey="high" stackId="a" fill="#d99900" />
                                        <Bar dataKey="medium" stackId="a" fill="#FFCD56" />
                                        <Bar dataKey="low" stackId="a" fill="#02a602" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default IncidentMetrics;
