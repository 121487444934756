import React from 'react';
import { Box, Grid, Paper, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Dashboard as DashboardIcon, PersonAdd as PersonAddIcon, Receipt as ReceiptIcon, Payment as PaymentIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import DashboardNav from './DashboardNav';

const Dashboard = () => {
    const { user } = React.useContext(AuthContext);
    const navigate = useNavigate();

    if (!user) {
        navigate('/login');
        return null;
    }


    const recentIncidents = user.company.incidents.slice(-5).reverse();

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <DashboardNav />
            <Box sx={{ flexGrow: 1, p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                            <Typography variant="h6" gutterBottom>
                                Yearly Breakup
                            </Typography>
                            <Typography variant="h4">
                                {user.company.yearlyIncidents} Incidents
                            </Typography>
                            <Typography color="textSecondary">
                                +{user.company.yearlyIncidentIncrease}% last year
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                            <Typography variant="h6" gutterBottom>
                                Monthly Incidents
                            </Typography>
                            <Typography variant="h4">
                                {user.company.monthlyIncidents}
                            </Typography>
                            <Typography color="textSecondary">
                                +{user.company.monthlyIncidentIncrease}% last month
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                            <Typography variant="h6" gutterBottom>
                                Recent Incidents
                            </Typography>
                            <List>
                                {recentIncidents.map((incident, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={incident.description}
                                            secondary={new Date(incident.date).toLocaleString()}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Dashboard;
