// A component that represents the not implemented pages

import React from "react";
import DashboardNav from "./DashboardNav";
import { Grid } from "@mui/material";

const NotImplemented = () => {
    return (
        <Grid container >
            <DashboardNav />
            <Grid item xs={12} md={6} lg={4}>
                <h1>You are using Ewake.ai in demo version, this component is not accessible.</h1>
            </Grid>
        </Grid>
    );
}

export default NotImplemented;